import React from 'react';
import { Typography, Card } from '@mui/material';

interface ChooseLoanAmountFormProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

export const LoanCalculatorItemRoot = (props: ChooseLoanAmountFormProps) => {
  return (
    <Card
      sx={{
        p: {
          xs: 2,
          lg: 3.25,
        },
        borderRadius: {
          xs: 0,
          lg: 4,
        },
        backgroundColor: {
          xs: '#F6F7F8',
          lg: 'common.white',
        },
      }}
    >
      <Typography
        variant="headline6"
        textAlign="center"
        color="common.onPrimaryContainer"
        sx={{
          mb: {
            xs: 2.25,
            lg: 2.5,
          },
        }}
      >
        {props.title}
      </Typography>
      {props.children}
    </Card>
  );
};
