import React from 'react';
import { Typography, Card, Box } from '@mui/material';

interface ChooseLoanAmountFormProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

export const LoanCalculatorItemRoot = (props: ChooseLoanAmountFormProps) => {
  return (
    <Card
      sx={(theme) => ({
        p: {
          xs: 2.5,
          md: 3,
        },
        borderRadius: 1.875,
        backgroundColor: 'common.white',
        border: {
          xs: `1px solid ${theme.palette.neutral[95]}`,
          md: 'none',
        },
      })}
    >
      <Box
        sx={{
          margin: 'auto',
          maxWidth: {
            xs: 450,
            md: 'unset',
          },
        }}
      >
        <Typography
          variant="headline6"
          color="neutral.50"
          textAlign="center"
          sx={{
            mb: 2.25,
          }}
        >
          {props.title}
        </Typography>
        {props.children}
      </Box>
    </Card>
  );
};
