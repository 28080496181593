import {
  LANDING_REFERRER_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
} from '@/shared/constants';
import { getChangeAmountActions } from '@/entities/first-loan-calculator-v2/analytics';

export const getOtpCode = (apiUrl: string | undefined, phoneNumber: string) => {
  return fetch(`${apiUrl}/clients/auth/get_code`, {
    method: 'POST',
    body: JSON.stringify({
      phone_number: phoneNumber,
      referrer: localStorage.getItem(LANDING_REFERRER_STORAGE_KEY) || undefined,
      landing_url: localStorage.getItem(LANDING_URL_STORAGE_KEY) || undefined,
      app_version: 'web',
      meta: {
        landing_page_actions: getChangeAmountActions(),
      },
    }),
  });
};
