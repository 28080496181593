import React from 'react';
import { Typography } from '@mui/material';

interface SumChipProps {
  children: React.ReactNode | React.ReactNode[];
}

export const SumChip = (props: SumChipProps) => {
  const { children } = props;

  return (
    <Typography
      variant="headline9"
      sx={(theme) => ({
        background: theme.palette.neutral[95],
        color: theme.palette.neutral[50],
        p: theme.spacing(0.625, 1.75),
        borderRadius: 0.625,
      })}
    >
      {children}
    </Typography>
  );
};
