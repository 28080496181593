import { ApiInstance } from './instance';
import { IImage, TPageData } from '@/shared/types';
import { THomeBlockFirstLoanCalculator } from '@/shared/api/homepage-api';

export enum ComponentType {
  Paragraph = 'blog.article-paragraph',
  Image = 'blog.article-image',
  Button = 'shared.nav-link',
  Calculator = 'blog.first-loan-calcularor',
}

export type ArticleParagraph = {
  id: number;
  text: string;
  __component: ComponentType.Paragraph;
};

export type ArticleImage = {
  id: number;
  image: IImage;
  __component: ComponentType.Image;
};

export type ArticleButton = {
  id: number;
  text: string;
  to: string;
  __component: ComponentType.Button;
};

export type ArticleCalculator = {
  display: boolean;
  id: number;
  __component: ComponentType.Calculator;
};

export type ArticlePart =
  | ArticleParagraph
  | ArticleImage
  | ArticleButton
  | ArticleCalculator;

export type BlogArticle = {
  id: number;
  attributes: {
    createdAt: string;
    publishedAt: string;
    updatedAt: string;

    article: ArticlePart[];
    articleImageTitle: IImage;
    articleTitle: string;
  };
};

export type BlogPageDataAttributes = {
  h1: string;
  extendedArticlesListTitle: string;
  moreNewsTitle: string;
  calculator: THomeBlockFirstLoanCalculator;
};

export type BlogPageData = TPageData<BlogPageDataAttributes>;

export class BlogApi extends ApiInstance {
  constructor(locale?: string) {
    super(locale);
  }

  getArticles() {
    const query = {
      populate: '*',
    };

    return this._SendWithQuery<BlogArticle[]>('/blogs', query);
  }

  getArticle(id: string) {
    const query = {
      populate: ['article', 'article.image'],
    };

    return this._SendWithQuery<BlogArticle>(`/blogs/${id}`, query);
  }

  getContent(): Promise<any> {
    const query = {
      populate: [
        'calculator',
        'calculator.title',
        'calculator.subtitle',
        'calculator.calculator',
        'calculator.calculatorSelect',
        'calculator.imageDesktop',
        'calculator.imageMobile',
        'calculatorSelect',
        'seo',
        'seo.metaSocial',
        'seo.metaSocial.image',
        'seo.metaImage',
      ],
    };

    return this._SendWithQuery<BlogPageData>('/blog-page', query);
  }
}
