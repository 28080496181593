import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  USER_ACTIONS_STORAGE_KEY,
} from '@/shared/constants';

export const addChangeAmountAction = (amount: number) => {
  const timestamp = new Date().getTime();
  const valueToRecord = {
    timestamp,
    amount,
  };
  const landingPageActions = JSON.parse(
    localStorage.getItem(USER_ACTIONS_STORAGE_KEY) || '[]',
  );
  landingPageActions.push(valueToRecord);

  localStorage.setItem(
    USER_ACTIONS_STORAGE_KEY,
    JSON.stringify(landingPageActions),
  );
};

export const getChangeAmountActions = () => {
  const data = localStorage.getItem(USER_ACTIONS_STORAGE_KEY);

  if (data) {
    return JSON.parse(data);
  }
  return null;
};

export const clearChangeAmountActions = () => {
  localStorage.removeItem(USER_ACTIONS_STORAGE_KEY);
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
};
