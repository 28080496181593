import React, { useEffect, useRef, useState } from 'react';
import { Box, Slider, Stack, Typography, useTheme } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { useTranslations } from 'use-intl';
import { THomeBlockFirstLoanCalculator } from '@/shared/api/homepage-api';
import { useConfig } from '@/shared/hooks/use-config';
import { GradientButton, InputPhone } from '@/shared/ui';
import {
  getPhoneInputParentElement,
  isTambadanaMirror,
  LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID,
  redirectTambadanaWhattsapp,
  redirectToAccount,
  useFormatters,
} from '@/utils';
import {
  AB_TESTING_REDIRECT_STORAGE_KEY,
  LANDING_REFERRER_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
} from '@/shared/constants';
import { LoanCalculatorItemRoot, CalculatorOutputValues, SumChip } from './ui';
import {
  addChangeAmountAction,
  clearChangeAmountActions,
  getChangeAmountActions,
} from './analytics';
import { useBoolean } from '@/shared/hooks';
import { ABTestingBlockNames, setToLocalStorage } from '@/features/ab-testing';
import { useUtmParams } from '@/shared/lib';
import { getOtpCode } from '@/features/otp-code-form';
import { useSearchParams } from 'next/navigation';

type FirstLoanCalculatorProps = Pick<
  THomeBlockFirstLoanCalculator,
  'calculator'
>;

export const FirstLoanCalculatorWithSlider = (
  props: FirstLoanCalculatorProps,
) => {
  const { calculator } = props;

  const {
    title,
    buttonGradientAnimation,
    min,
    max,
    defaultValue,
    step,
    multiplicationFactor,
    buttonText,
    phoneInputPlaceholder,
    showAmountOutput,
    minSumForRepeatOnlyLoans,
    loanAmountTitle,
    repeatOnlyText,
    finalSumFootnote,
  } = calculator;

  const t = useTranslations();

  const config = useConfig();
  const theme = useTheme();
  const {
    formatCurrency,
    formatPhoneNumber,
    formatNumberWithCommonApiContextSeparator,
  } = useFormatters();
  const utmParams = useUtmParams();
  const isTouchedAmount = useBoolean(false);
  const [amount, setAmount] = useState<number>(defaultValue);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [inputError, setInputError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>();
  const inputPhoneRef = useRef<HTMLDivElement>(null);
  const [debouncedAmount] = useDebounce(amount, 700);
  const searchParams = useSearchParams();

  const tambadanaMirror = isTambadanaMirror();

  useEffect(() => {
    const amountActions = getChangeAmountActions();
    if (!amountActions?.length) {
      addChangeAmountAction(amount);
    }
  }, []);

  useEffect(() => {
    if (isTouchedAmount.value) {
      addChangeAmountAction(debouncedAmount);
    }
  }, [debouncedAmount, isTouchedAmount.value]);

  const errorMessageByCode: Record<string, string> = {
    countdown_error: t('errors.phoneNumber.countdown_error'),
  };
  const helperText = errorCode
    ? errorMessageByCode[errorCode] || t('errors.phoneNumber.error')
    : null;

  const setFocusToPhoneInput = () => {
    const input = inputPhoneRef?.current?.querySelector('input');
    if (input) {
      input.focus();
    }
  };

  const setPhoneInputError = () => {
    // TODO: investigate why inputRef prop does not working
    setInputError(true);
    setFocusToPhoneInput();
  };

  useEffect(() => {
    if (searchParams.get('sourceFrom') === 'accountHref') {
      setFocusToPhoneInput();
    }
  }, [searchParams]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (tambadanaMirror) {
      redirectTambadanaWhattsapp();
    }

    if (phoneNumber.length < 2) {
      setPhoneInputError();
      return;
    }
    setInputError(false);
    const phoneNumberStorageItemKey = 'landing.phoneNumber';

    sessionStorage.setItem(
      phoneNumberStorageItemKey,
      phoneNumber.split(' ').join(''),
    );

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    getOtpCode(config?.apiUrl, formattedPhoneNumber)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setErrorCode(data.error.code);
          setPhoneInputError();
        } else {
          clearChangeAmountActions();
          setToLocalStorage(
            AB_TESTING_REDIRECT_STORAGE_KEY,
            ABTestingBlockNames.Calculator,
          );

          const landingInfo = {
            'landing.phoneNumber': formattedPhoneNumber,
            'landing.referrer': localStorage.getItem(
              LANDING_REFERRER_STORAGE_KEY,
            ),
            'landing.url': localStorage.getItem(LANDING_URL_STORAGE_KEY),
            'landing.abTests': ABTestingBlockNames.Calculator,
          };

          const landingInfoEncoded = btoa(JSON.stringify(landingInfo));

          const params = new URLSearchParams();

          params.append('authId', data.data.auth_id);
          params.append('sourceFrom', 'landing');
          params.append('landingInfo', landingInfoEncoded);

          for (const key in utmParams) {
            if (utmParams.hasOwnProperty(key)) {
              params.append(key, utmParams[key].toString());
            }
          }

          redirectToAccount(`/auth?${params.toString()}`);
        }
      })
      .catch((error) => {
        setErrorCode('unknown');
        setPhoneInputError();
      });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    const phoneInputElementParent = getPhoneInputParentElement();
    if (phoneInputElementParent) {
      phoneInputElementParent.style.boxShadow = '';
    }
    setPhoneNumber(e.currentTarget.value);
  };

  const handleAmountChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      isTouchedAmount.on();
      setAmount(newValue);
    }
  };

  const showRepeatLoanOnlyChip = amount >= minSumForRepeatOnlyLoans;

  const phoneInputNode = (
    <InputPhone
      id={LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID}
      placeholder={phoneInputPlaceholder}
      onChange={handlePhoneNumberChange}
      value={phoneNumber}
      error={inputError}
      helperText={helperText}
      ref={inputPhoneRef}
      sx={{
        '& .MuiInputBase-root': {
          overflow: 'auto',
        },
      }}
    />
  );

  return (
    <LoanCalculatorItemRoot title={title}>
      <Box component="form" onSubmit={handleSubmit}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={{
            xs: 0,
            md: 0.75,
          }}
        >
          <Typography variant="text6" color="neutral.50">
            {loanAmountTitle}
          </Typography>
          <Typography variant="headline8" color="neutral.20">
            {formatCurrency(amount)}
          </Typography>
        </Stack>
        <Box mb={2}>
          <Slider
            aria-label={loanAmountTitle}
            defaultValue={defaultValue}
            step={step}
            min={min}
            max={max}
            valueLabelDisplay="off"
            marks={false}
            value={amount}
            onChange={handleAmountChange}
            sx={{
              color: theme.palette.common.primary,
              height: 8,
              '& .MuiSlider-rail': {
                color: theme.palette.neutral[90],
              },
              '& .MuiSlider-thumb': {
                height: 18,
                width: 18,
                backgroundColor: theme.palette.common.primary,
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                  boxShadow: 'inherit',
                },
                '&:hover': {
                  boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.46)',
                },
                '&:before': {
                  display: 'none',
                },
              },
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={{
              xs: 1,
              md: 0,
            }}
            sx={{ minHeight: 24 }}
          >
            <SumChip>{formatNumberWithCommonApiContextSeparator(min)}</SumChip>
            {showRepeatLoanOnlyChip ? (
              <Typography
                variant="text7"
                sx={(theme) => ({
                  background: theme.palette.primary[95],
                  color: theme.palette.common.primary,
                  p: theme.spacing(0.5, 1.25),
                  borderRadius: 0.625,
                })}
              >
                {repeatOnlyText}
              </Typography>
            ) : null}

            <SumChip>{formatNumberWithCommonApiContextSeparator(max)}</SumChip>
          </Stack>
        </Box>
        {tambadanaMirror || phoneInputNode}
        {showAmountOutput ? (
          <CalculatorOutputValues
            amount={amount}
            finalSumFootnote={finalSumFootnote}
            multiplicationFactor={multiplicationFactor}
          />
        ) : null}

        <GradientButton
          id={tambadanaMirror ? 'whatsapp_contact_us_button' : undefined}
          type="submit"
          animate={buttonGradientAnimation}
          size="large"
          sx={{
            p: 2.25,
            mt: {
              xs: 2,
              md: 3,
            },
          }}
          fullWidth
        >
          {tambadanaMirror ? t('button.contactUs') : buttonText}
        </GradientButton>
      </Box>
    </LoanCalculatorItemRoot>
  );
};
