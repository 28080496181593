import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFormatters } from '@/utils';
import { FirstLoanCalculator } from '@/shared/api/homepage-api';

type CalculatorOutputValuesProps = Pick<
  FirstLoanCalculator,
  'finalSumFootnote' | 'multiplicationFactor'
> & {
  amount: number;
};

export const CalculatorOutputValues = (props: CalculatorOutputValuesProps) => {
  const { finalSumFootnote, amount, multiplicationFactor } = props;
  const { formatCurrency } = useFormatters();

  return (
    <Box mt={2}>
      <Typography
        color="common.onPrimaryContainer"
        textAlign="center"
        variant="headline6"
        fontWeight={600}
      >
        {formatCurrency(amount * multiplicationFactor)}
      </Typography>
      <Typography
        color="common.onPrimaryContainer"
        textAlign="center"
        variant="text8"
      >
        {finalSumFootnote}
      </Typography>
    </Box>
  );
};
