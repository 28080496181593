import React from 'react';
import { SectionOuter } from '@/shared/ui/section-outer';
import { Box, Grid, useTheme } from '@mui/material';
import { THomeBlockFirstLoanCalculator } from '@/shared/api/homepage-api';
import Image from 'next/image';
import { Parser } from '@/shared/ui';
import { LOAN_CALCULATOR_ITEM_NODE_ID } from '@/utils';
import { FirstLoanCalculatorWithSlider } from '@/entities/first-loan-calculator/calculator-item-with-slider';

type FirstLoanCalculatorOuterProps = Pick<
  THomeBlockFirstLoanCalculator,
  'title' | 'subtitle' | 'calculator' | 'imageDesktop' | 'imageMobile'
>;

export const FirstLoanCalculatorOuter = (
  props: FirstLoanCalculatorOuterProps,
) => {
  const { calculator, subtitle, title, imageMobile, imageDesktop } = props;

  const theme = useTheme();

  const leftColumn = (
    <Box
      sx={{
        py: {
          xs: 2,
          sm: 4,
        },
        pl: {
          xs: 2,
          sm: 4,
        },
        pr: {
          xs: 0,
          lg: 2,
        },
      }}
    >
      {title ? (
        <Box
          sx={{
            '& .MuiTypography-root': {
              m: 0,
              letterSpacing: 0,
              fontWeight: 700,
              fontSize: {
                xs: 22,
                sm: 40,
                md: title.fontSize,
              },
              lineHeight: {
                xs: '32.5px',
                sm: '50px',
                md: title.lineHeight + 'px',
              },
            },
            mb: {
              xs: 2,
              lg: 3,
            },
          }}
        >
          <Parser data={title.text} />
        </Box>
      ) : null}

      {subtitle ? (
        <Box
          sx={{
            m: 0,
            '& > *': {
              fontWeight: {
                xs: 400,
                lg: 600,
              },
              fontSize: {
                xs: 14,
                sm: 16,
                md: subtitle.fontSize,
              },
              lineHeight: {
                xs: '20.6px',
                sm: 'auto',
                md: subtitle.lineHeight + 'px',
              },
            },
          }}
        >
          <Parser data={subtitle.text} />
        </Box>
      ) : null}
    </Box>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        minHeight: 425,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={6}
          md={7}
          lg={4}
          sx={{
            display: 'flex',
            alignItems: calculator?.showAmountOutput ? 'center' : 'start',
          }}
        >
          {leftColumn}
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          order={{
            xs: 2,
            lg: 0,
          }}
        >
          <Box
            py={{
              xs: 0,
              sm: 2,
              lg: 4,
            }}
            sx={{
              backgroundColor: {
                xs: '#F6F7F8',
                lg: 'transparent',
              },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                maxWidth: '600px',
                width: '100%',
              }}
            >
              <FirstLoanCalculatorWithSlider calculator={calculator} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={5} lg={4}>
          <Box
            sx={{
              backgroundImage: {
                lg: `url(${imageDesktop?.data?.attributes?.url})`,
              },
              width: '100%',
              height: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top right',
            }}
          >
            <Box
              sx={{
                height: '100%',
                justifyContent: 'flex-end',
                display: {
                  xs: 'flex',
                  lg: 'none',
                },
              }}
            >
              <Image
                src={imageMobile?.data?.attributes?.url}
                alt={imageMobile?.data?.attributes?.alternativeText || ''}
                width={imageMobile?.data?.attributes?.width}
                height={imageMobile?.data?.attributes?.height}
                style={{
                  height: 'auto',
                  objectFit: 'contain',
                  objectPosition: 'left bottom',
                  maxHeight: '400px',
                  width: 'auto',
                }}
                priority
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const sxProps = {
    borderRadius: {
      xs: 0,
      lg: 2,
    },
    bgcolor: {
      xs: theme.palette.common.white,
      lg: theme.palette.common.secondaryContainer,
    },
  };

  return (
    <Box id={LOAN_CALCULATOR_ITEM_NODE_ID}>
      <Box display={{ xs: 'block', lg: 'none' }}>
        <SectionOuter sx={sxProps} disablePaddings disableGutters>
          {content}
        </SectionOuter>
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <SectionOuter sx={sxProps} disablePaddings>
          {content}
        </SectionOuter>
      </Box>
    </Box>
  );
};
