import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip, Slider, Stack, Typography, useTheme } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { THomeBlockFirstLoanCalculator } from '@/shared/api/homepage-api';
import { useConfig } from '@/shared/hooks/use-config';
import { GradientButton, InputPhone } from '@/shared/ui';
import { LoanCalculatorItemRoot } from '@/entities/first-loan-calculator/loan-calculator-item-root';
import { CalculatorOutputValues } from '@/entities/first-loan-calculator/calculator-output-values';
import {
  addChangeAmountAction,
  clearChangeAmountActions,
  getChangeAmountActions,
} from './analytics';
import {
  getPhoneInputParentElement,
  LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID,
  useFormatters,
} from '@/utils';
import { useBoolean } from '@/shared/hooks';
import { getOtpCode } from '@/features/otp-code-form';
import { useSearchParams } from 'next/navigation';

type FirstLoanCalculatorProps = Pick<
  THomeBlockFirstLoanCalculator,
  'calculator'
>;

export const FirstLoanCalculatorWithSlider = (
  props: FirstLoanCalculatorProps,
) => {
  const { calculator } = props;

  const {
    title,
    buttonGradientAnimation,
    min,
    max,
    defaultValue,
    step,
    multiplicationFactor,
    buttonText,
    phoneInputPlaceholder,
    showAmountOutput,
    minSumForRepeatOnlyLoans,
    loanAmountTitle,
    repeatOnlyText,
    finalSumFootnote,
  } = calculator;

  const config = useConfig();
  const theme = useTheme();
  const {
    formatCurrency,
    formatPhoneNumber,
    formatNumberWithCommonApiContextSeparator,
  } = useFormatters();
  const isTouchedAmount = useBoolean(false);
  const [amount, setAmount] = useState<number>(defaultValue);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [inputError, setInputError] = useState(false);
  const inputPhoneRef = useRef<HTMLDivElement>(null);
  const [debouncedAmount] = useDebounce(amount, 700);
  const searchParams = useSearchParams();

  useEffect(() => {
    const amountActions = getChangeAmountActions();
    if (!amountActions?.length) {
      addChangeAmountAction(amount);
    }
  }, []);

  useEffect(() => {
    if (isTouchedAmount.value) {
      addChangeAmountAction(debouncedAmount);
    }
  }, [debouncedAmount, isTouchedAmount.value]);

  const setFocusToPhoneInput = () => {
    const input = inputPhoneRef?.current?.querySelector('input');
    if (input) {
      input.focus();
    }
  };

  const setPhoneInputError = () => {
    // TODO: investigate why inputRef prop does not working
    setInputError(true);
    setFocusToPhoneInput();
  };

  useEffect(() => {
    if (searchParams.get('sourceFrom') === 'accountHref') {
      setFocusToPhoneInput();
    }
  }, [searchParams]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (phoneNumber.length < 2) {
      setPhoneInputError();
      return;
    }
    setInputError(false);
    const phoneNumberStorageItemKey = 'landing.phoneNumber';

    sessionStorage.setItem(
      phoneNumberStorageItemKey,
      phoneNumber.split(' ').join(''),
    );

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    getOtpCode(config?.apiUrl, formattedPhoneNumber)
      .then((res) => res.json())
      .then((data) => {
        clearChangeAmountActions();
        window.location.href = `/account/auth?authId=${data.data.auth_id}&sourceFrom=landing`;
      })
      .catch(() => {
        setPhoneInputError();
      });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    const phoneInputElementParent = getPhoneInputParentElement();
    if (phoneInputElementParent) {
      phoneInputElementParent.style.boxShadow = '';
    }
    setPhoneNumber(e.currentTarget.value);
  };

  const handleAmountChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      isTouchedAmount.on();
      setAmount(newValue);
    }
  };

  const showRepeatLoanOnlyChip = amount >= minSumForRepeatOnlyLoans;

  return (
    <LoanCalculatorItemRoot title={title}>
      <Box component="form" onSubmit={handleSubmit}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={{
            xs: 0,
            lg: 1,
          }}
        >
          <Typography
            variant="headline8"
            fontWeight={400}
            fontSize={18}
            color="neutral.50"
          >
            {loanAmountTitle}
          </Typography>
          <Typography variant="headline6" fontSize={24}>
            {formatCurrency(amount)}
          </Typography>
        </Stack>
        <Box mb={2}>
          <Slider
            aria-label={loanAmountTitle}
            defaultValue={defaultValue}
            step={step}
            min={min}
            max={max}
            valueLabelDisplay="off"
            marks={false}
            value={amount}
            onChange={handleAmountChange}
            sx={{
              color: theme.palette.common.primary,
              height: 8,
              '& .MuiSlider-rail': {
                color: '#E1E1E1',
              },
              '& .MuiSlider-thumb': {
                height: 24,
                width: 24,
                backgroundColor: theme.palette.common.primary,
                border: '5px solid white',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                  boxShadow: 'inherit',
                },
                '&:hover': {
                  boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.46)',
                },
                '&:before': {
                  display: 'none',
                },
              },
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={{
              xs: 1,
              lg: 0,
            }}
            sx={{ minHeight: 24 }}
          >
            <Typography variant="text8" color="neutral.50">
              {formatNumberWithCommonApiContextSeparator(min)}
            </Typography>
            {showRepeatLoanOnlyChip ? (
              <Chip
                size="small"
                label={repeatOnlyText}
                sx={{
                  borderRadius: '5px',
                  background: theme.palette.primary[95],
                  color: theme.palette.common.primary,
                  fontWeight: 600,
                  fontSize: 12,
                }}
              />
            ) : null}

            <Typography variant="text8" color="neutral.50">
              {formatNumberWithCommonApiContextSeparator(max)}
            </Typography>
          </Stack>
        </Box>
        <InputPhone
          id={LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID}
          placeholder={phoneInputPlaceholder}
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
          error={inputError}
          ref={inputPhoneRef}
        />
        {showAmountOutput ? (
          <CalculatorOutputValues
            amount={amount}
            finalSumFootnote={finalSumFootnote}
            multiplicationFactor={multiplicationFactor}
          />
        ) : null}

        <GradientButton
          type="submit"
          animate={buttonGradientAnimation}
          size="large"
          sx={{
            mt: 2,
          }}
          fullWidth
        >
          {buttonText}
        </GradientButton>
      </Box>
    </LoanCalculatorItemRoot>
  );
};
