export const setToLocalStorage = (testName: string, value: string) => {
  try {
    const ABTestingStorageKeys = localStorage.getItem(testName);
    let ABTestingStorageKeysParsed: Record<string, string> = {};
    if (ABTestingStorageKeys) {
      try {
        ABTestingStorageKeysParsed = JSON.parse(ABTestingStorageKeys);
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }
    }
    ABTestingStorageKeysParsed[testName] = value;
    localStorage.setItem(
      'landing.abTests',
      JSON.stringify(ABTestingStorageKeysParsed),
    );
  } catch (error) {
    console.error('Error setting value to localStorage:', error);
  }
};
