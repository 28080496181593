import { ComponentType } from '@/shared/types';
import {
  AB_TESTING_COMPONENTS_KEY,
  AB_TESTING_CREDIT_LINE_TEST_KEY,
  AB_TESTING_INDEX_KEY,
} from '@/features/ab-testing/constants';
import { TPageDataContent } from '@/shared/api/homepage-api';
import Cookies from 'cookies';
import { getRandomIntegerWithDifferentProbabilities } from '@/shared/lib';

export const withCreditLineTest = (
  components: TPageDataContent[] | null,
  cookiesInstance: Cookies,
  utmCampaignValue: string | string[] | undefined,
) => {
  if (!components) return null;
  let res = null;

  const isCreditLineTest =
    utmCampaignValue?.includes('creditline_test') ||
    !!cookiesInstance.get(AB_TESTING_CREDIT_LINE_TEST_KEY);

  if (isCreditLineTest) {
    cookiesInstance.set(AB_TESTING_CREDIT_LINE_TEST_KEY, '1');
    //check "ab-testing.credit-limit-slider"
    const ABTestingCreditLimitSlider = components?.find(
      (component) => component.__component === ComponentType.CreditLimitSlider,
    );
    if (ABTestingCreditLimitSlider) {
      res = [ABTestingCreditLimitSlider];
    }
  } else {
    res = components.filter(
      (component) => component.__component !== ComponentType.CreditLimitSlider,
    );
  }

  return res;
};

export const getRandomComponentIndex = (
  components: TPageDataContent[] | null,
  cookiesInstance: Cookies,
) => {
  if (!components) return null;
  let abTestIndex = Number(cookiesInstance.get(AB_TESTING_INDEX_KEY));

  if (Array.isArray(components) && components.length) {
    const testingComponentsSaved = cookiesInstance.get(
      AB_TESTING_COMPONENTS_KEY,
    );
    const testingComponents = components
      .map((item) => item.__component)
      .join('');

    if (testingComponentsSaved !== testingComponents) {
      cookiesInstance.set(AB_TESTING_COMPONENTS_KEY, testingComponents);
      cookiesInstance.set(AB_TESTING_INDEX_KEY, '');
    }

    if (!Number.isInteger(abTestIndex)) {
      const randomIndex = getRandomIntegerWithDifferentProbabilities(
        components.map((item, index) => [index, item.relativeWeight]),
      );

      abTestIndex = randomIndex;
      cookiesInstance.set(AB_TESTING_INDEX_KEY, randomIndex.toString());
    }
  }

  return abTestIndex;
};
