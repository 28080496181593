import React from 'react';
import { SectionOuter } from '@/shared/ui/section-outer';
import { Box, Grid, Typography, Container } from '@mui/material';
import Image from 'next/image';

import { THomeBlockFirstLoanCalculatorV2 } from '@/shared/api/homepage-api';
import { LOAN_CALCULATOR_ITEM_NODE_ID } from '@/utils';
import { FirstLoanCalculatorWithSlider } from './calculator-item-with-slider';
import { NotStyledParser } from '@/shared/ui';

type FirstLoanCalculatorOuterProps = Pick<
  THomeBlockFirstLoanCalculatorV2,
  | 'title'
  | 'subtitle'
  | 'title_mobile'
  | 'subtitle_mobile'
  | 'calculator'
  | 'imageDesktop'
  | 'imageMobile'
  | 'backgroundDesktop'
>;

export const FirstLoanCalculatorOuter_v2 = (
  props: FirstLoanCalculatorOuterProps,
) => {
  const {
    calculator,
    subtitle,
    title,
    title_mobile,
    subtitle_mobile,
    imageMobile,
    imageDesktop,
    backgroundDesktop,
  } = props;

  const content = (
    <Box
      sx={{
        display: 'flex',
        minHeight: 425,
        background: {
          xs: 'none',
          md: `url(${backgroundDesktop?.data.attributes.url}) no-repeat right center`,
        },
        backgroundSize: {
          md: 'contain',
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          alignSelf="flex-end"
          sx={{
            backgroundColor: {
              xs: 'primary.95',
              md: 'transparent',
            },
            pb: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Box
            display={{
              xs: 'flex',
              md: 'none',
            }}
            justifyContent="center"
            px={5}
          >
            <Image
              src={imageMobile?.data?.attributes?.url}
              alt={imageMobile?.data?.attributes?.alternativeText || ''}
              height={imageMobile?.data?.attributes?.height}
              width={imageMobile?.data?.attributes?.width}
              style={{
                height: 'auto',
              }}
            />
          </Box>
          <Box
            display={{
              xs: 'none',
              md: 'flex',
            }}
            px={4}
          >
            <Image
              src={imageDesktop?.data?.attributes?.url}
              alt={imageDesktop?.data?.attributes?.alternativeText || ''}
              height={imageDesktop?.data?.attributes?.height}
              width={imageDesktop?.data?.attributes?.width}
              style={{
                height: 'auto',
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          order={{
            xs: 2,
            md: 0,
          }}
          sx={{
            mt: {
              xs: -2,
              md: 0,
            },
          }}
        >
          <Box
            py={{
              xs: 0,
              md: 3.75,
            }}
            sx={{
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <FirstLoanCalculatorWithSlider calculator={calculator} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box id={LOAN_CALCULATOR_ITEM_NODE_ID}>
      <Box display={{ xs: 'block', md: 'none' }}>
        <Container
          sx={{ mb: 2, mt: { xs: 2.5, md: 3.75 }, textAlign: 'center' }}
        >
          <Typography variant="headline5" color="neutral.20" mb={1}>
            <NotStyledParser data={title_mobile} />
          </Typography>
          <Typography variant="text6" color="neutral.50">
            <NotStyledParser data={subtitle_mobile} />
          </Typography>
        </Container>

        <SectionOuter
          sx={{
            background: 'transparent',
            borderRadius: 0,
          }}
          disablePaddings
          disableGutters
        >
          {content}
        </SectionOuter>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Container
          sx={{
            my: 3.375,
            textAlign: 'center',
          }}
        >
          <Typography variant="headline1" color="neutral.20" mb={1.25}>
            <NotStyledParser data={title} />
          </Typography>
          <Typography variant="text4" color="neutral.50">
            <NotStyledParser data={subtitle} />
          </Typography>
        </Container>
        <SectionOuter
          sx={{
            borderRadius: 2,
            bgcolor: 'primary.95',
          }}
          disablePaddings
        >
          {content}
        </SectionOuter>
      </Box>
    </Box>
  );
};
